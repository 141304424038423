<template>
  <full-page-template>
    <div class="bg-articles" :class="{ active : loaded }" />
    <div class="p-articles">
      <div class="container">
        <div class="wrapper">
          <h1 class="section-title ta--center">
            Статьи
          </h1>
          <div class="divider" />
          <p class="description ta--center mb-xl">
            Здесь вы можете ознакомиться с материалами, которые могут помочь вам в экстренных ситауциях <br> при потере ценностей или любимых питомцев.
          </p>
          <div
            v-masonry
            item-selector=".col"
            transition-duration="0.3s"
            :gutter="32"
            :fit-width="true"
            class="masonry mb-xl"
          >
            <div
              v-for="item in articles"
              :key="item.id"
              v-masonry-tile
              class="col"
            >
              <article-item :article="item" />
            </div>
          </div>
        </div>
      </div>
    </div>
    <app-footer />
  </full-page-template>
</template>

<script>
import AppFooter from '@/components/common/AppFooter'
import FullPageTemplate from '@/layouts/FullPageTemplate'
import ArticleItem from '@/components/articles/ArticleItem'

export default {
  name: 'Articles',
  components: {
    AppFooter,
    FullPageTemplate,
    ArticleItem
  },
  data () {
    return {
      loaded: false,
      articles: [
        {
          id: 1,
          img: 'article-1.jpg',
          time: '3 мин.',
          title: 'Вы знаете, что по статистике больше всего собак теряется в летний период?',
          description: 'Это связано с тем, что многие вывозят своих любимцев на дачи и берут с собой в путешествия, и часто животное теряет голову от неожиданной. Это связано с тем, что многие вывозят своих любимцев на дачи и берут с собой в путешествия, и часто животное теряет голову от неожиданной ...'
        },
        {
          id: 2,
          img: 'article-2.jpg',
          time: '3 мин.',
          title: 'Забота о близких',
          description: 'Люди преклонного возраста, как и дети, увы, часто теряются. Бывает, что пожилой человек вышел в магазин и четко все понимает, куда и зачем идет, а бывает, что  вышел и не может вспомнить дорогу домой. При этом, как правило, пожилые люди не....'
        }
      ]
    }
  },
  mounted () {
     if (document.readyState === 'complete') {
      this.loaded = true
    } else {
      window.onload = () => {
        this.loaded = true
      }
    }
  }
}
</script>

<style lang="scss" scoped>
  .bg-articles {
    &.active {
      opacity: 1;
      transform: none;
    }
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: url('~@/assets/img/bg-articles.png') center / cover no-repeat;
    background-attachment: fixed;
    opacity: 0;
    transition: 1s;
    transform: scale(1.2);
  }
  .p-articles {
    position: relative;
    min-height: 100vh;
    .wrapper {
      z-index: 9;
      display: flex;
      flex-direction: column;
      max-width: 864px;
      .masonry {
        align-self: center;
        .col {
          margin-bottom: 32px;
        }
      }
    }
  }
</style>
